import { request } from "Libs/platform";
import { ObservabilityPipelineProjectUrls } from "Reducers/observability/pipeline";
import { ObservabilitySandboxAccessResponse } from "Reducers/observability/sandbox";

import {
  Context,
  ContextMode,
  DistributionCost,
  ObservabilityPipelineContinuousProfilingApps,
  ObservabilityPipelineServerCachesOverview,
  ObservabilityPipelineAgilisMetrics
} from "./types";

class ObservabilityPipelineHttpClient {
  private projectId: string;
  private branchMachineName: string;
  private configuration: ObservabilityPipelineProjectUrls;

  constructor(
    projectId: string,
    branchMachineName: string,
    configuration: ObservabilityPipelineProjectUrls
  ) {
    this.projectId = projectId;
    this.branchMachineName = branchMachineName;
    this.configuration = configuration;
  }

  async getSandboxAccess(): Promise<ObservabilitySandboxAccessResponse> {
    const action = "conprof_sandbox_access";
    this.assertActionSupport(action);

    return request(
      this.configuration.urls[action],
      "GET"
    ) as Promise<ObservabilitySandboxAccessResponse>;
  }

  async getContinuousProfilingApps(): Promise<ObservabilityPipelineContinuousProfilingApps> {
    const action = "conprof_applications";
    this.assertActionSupport(action);

    return request(this.configuration.urls[action], "GET");
  }

  async getServerCachesOverview(
    from: number,
    to: number,
    distribution_cost: DistributionCost,
    contexts_mode: ContextMode,
    contexts: Context[]
  ): Promise<ObservabilityPipelineServerCachesOverview> {
    const action = "server_caches_overview";
    this.assertActionSupport(action);

    const searchParams = new URLSearchParams({
      from: from.toString(),
      to: to.toString(),
      distribution_cost,
      contexts_mode: contexts_mode.toString()
    });

    for (const context of contexts) {
      searchParams.append("contexts[]", context);
    }

    return request(
      this.configuration.urls[action] + "?" + searchParams.toString(),
      "GET"
    );
  }

  async getAgilisMetrics(props: {
    start: number,
    end: number,
    grain: number
  }
  ): Promise<ObservabilityPipelineAgilisMetrics> {
    const action = "agilis_metrics";
    this.assertActionSupport(action);

    const searchParams = new URLSearchParams({
      from: props.start.toString(),
      to: props.end.toString(),
      grain: props.grain.toString()
    });

    return request(
      this.configuration.urls[action] + "?" + searchParams.toString(),
      "GET"
    );
  }

  getKey(): string {
    return `${this.projectId}/${this.branchMachineName}`;
  }

  private assertActionSupport(action: string) {
    if (action in this.configuration.urls === false) {
      throw new Error(`unsupported action "${action}"`);
    }
  }
}

export default ObservabilityPipelineHttpClient;
